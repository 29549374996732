<template>
    <div class="page-wechat-webview">
        <div class="info-header">{{info.title}}</div>
        <div class="info-content" v-html="info.content"></div>
        <div class="info-footer">
            <div class="logo">鱼大大</div>
            <div class="time">{{time}}</div>
        </div>
    </div>
</template>

<script>
import { Toast } from "vant";
export default {
    name: "WechatWebView",
    data() {
        return {
            info: {},
            time: ''
        };
    },
    created() {
        const { token, id, type } = this.$route.query
        !token ? this.$router.replace({
            path: "/404"
        }) : this.loadInfo(token, id, type);

    },
    methods: {
        loadInfo(token, id, type) {
            const vm = this
            vm.$http({
                type: "get",
                url: `${vm.$config.base[type == 1 ? 'MP_WECHAT_BASE_URL' : 'DEALER_WECHAT_BASE_URL']}notice/${id}`,
                headers: { 'x-access-token':  token },
                params: {
                    probeId: vm.$route.query.uuid
                },
                except: true
            }).then(res => {
                if (res.code != 1) {
                    Toast(res.message || "网络错误");
                    return;
                }
                if (res.data) {
                    const time = new Date(res.data.releaseAt * 1000)
                    vm.time = `${time.getMonth() + 1}-${time.getDate()}`
                }
                vm.info = res.data
            });
        }
    }
};
</script>

<style lang="less" scoped>
.page-wechat-webview {
    background: #fff;
    padding: 15px 10px;
    font-size: 16px;
    color: #555;
    .info-header {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 15px;
    }
    .info-content {
        padding-bottom: 30px;
    }
    .info-footer {
        text-align: right;
        line-height: 30px;
    }
}
</style>


